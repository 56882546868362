<!--
 * @Description: 内容发布--平台公告列表页面
 * @Author: 小广
 * @Date: 2019-06-17 18:16:21
 * @LastEditors: 小广
 * @LastEditTime: 2019-06-28 14:26:41
 -->
<template>
  <div class="PlatformNoticeList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="标题" v-model="searchParams.title"></v-input>
         <v-select v-if="showAdd" label="公告类型" v-model="searchParams.noticeType" :options="noticeTypeOps"></v-select>
        <v-select label="状态" v-model="searchParams.status" :options="statusOps"></v-select>
        <v-datepicker label="发布时间" type="rangedatetimer" :startTime.sync="searchParams.startTime" :endTime.sync="searchParams.endTime"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button v-if="scope.row.modifyFlag" text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
        <v-button v-if="scope.row.status === 1 && scope.row.modifyFlag" text="关闭" type="text" permission="delete" @click="offHandle(scope.row)"></v-button>
        <v-button text="查看" type="text" permission="view" @click="clickNotice(scope.row)"></v-button>
      </template>
    </list>
    <notice-view v-if="noticeDialog" :id="noticeId" :detailVisible.sync="noticeDialog"></notice-view>
  </div>
</template>

<script>
import { getListURL, getNoticeTypeURL, closeHandleURL } from './api'
import { statusMap, statusOps } from './map'
import { mapHelper } from 'common/utils'
import NoticeView from './NoticeView.vue'

export default {
  name: 'PlatformNoticeList',
  components: {
    NoticeView
  },
  data () {
    return {
      searchUrl: getListURL,
      noticeDialog: false,
      noticeId: undefined,
      statusOps: statusOps(1),
      noticeTypeOps: [],
      searchParams: {
        title: '',
        status: undefined,
        noticeType: undefined,
        startTime: '',
        endTime: ''
      },
      headers: [
        {
          prop: 'noticeTitle',
          label: '公告标题'
        },
        {
          prop: 'noticeTypeName',
          label: '类别'
        },
        {
          prop: 'showNoticeTime',
          label: '发布时间段',
          formatter (row) {
            return `${row.noticeTimeStart} ~ ${row.noticeTimeEnd}`
          }

        },
        {
          prop: 'statusText',
          label: '状态',
          formatter (row) {
            return statusMap[row.status]
          }
        }
      ]
    }
  },

  computed: {
    // 根据账户角色权限，获取是否要显示公告类型筛选(一直返回true，暂时不做筛选)
    showAdd () {
      let userInfo = this.$store.state.userInfo
      let show = true
      if (userInfo) {
        if (userInfo.userType && (userInfo.userType === '100' || userInfo.userType === '106')) {
          show = true
        }
      }
      return show
    }
  },

  created () {
    if (this.showAdd) {
      this.getNoticeType()
    }
  },

  methods: {
    create () {
      this.$router.push({
        name: 'platformNoticeForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'platformNoticeForm',
        query: {
          id: row.id
        }
      })
    },
    clickNotice (row) {
      this.noticeId = row.id
      this.noticeDialog = true
    },
    // 关闭的操作
    async offHandle (row) {
      let isOk = await this.$confirm('确认关闭吗？', { title: '提示' })
      isOk && this.requestForOff(row)
    },

    // 关闭的请求
    requestForOff (row) {
      let _this = this
      this.$axios({
        method: 'PUT',
        url: closeHandleURL,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        data: this.$qs.stringify({
          id: row.id
        })
      }).then(res => {
        if (res.status === 100) {
          _this.$refs.list.searchData()
          _this.$message.success('操作成功')
        }
      })
    },

    // 获取公告类型
    getNoticeType () {
      let _this = this
      this.$axios.get(getNoticeTypeURL).then(res => {
        if (res.status === 100) {
          let noticeTypeList = []
          let list = res.data || []
          list.forEach(item => {
            noticeTypeList.push({
              text: item.value,
              value: item.id
            })
          })
          // 处理公告类型
          if (noticeTypeList.length) {
            const { setOps: noticeTypeOps } = mapHelper.setMap(noticeTypeList)
            _this.noticeTypeOps = noticeTypeOps(1)
          }
        }
      })
    }
  }
}
</script>
