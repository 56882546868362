var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "PlatformNoticeList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          searchUrl: _vm.searchUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "新增", permission: "add" },
                  on: { click: _vm.create },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "标题" },
                  model: {
                    value: _vm.searchParams.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "title", $$v)
                    },
                    expression: "searchParams.title",
                  },
                }),
                _vm.showAdd
                  ? _c("v-select", {
                      attrs: { label: "公告类型", options: _vm.noticeTypeOps },
                      model: {
                        value: _vm.searchParams.noticeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "noticeType", $$v)
                        },
                        expression: "searchParams.noticeType",
                      },
                    })
                  : _vm._e(),
                _c("v-select", {
                  attrs: { label: "状态", options: _vm.statusOps },
                  model: {
                    value: _vm.searchParams.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "status", $$v)
                    },
                    expression: "searchParams.status",
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    label: "发布时间",
                    type: "rangedatetimer",
                    startTime: _vm.searchParams.startTime,
                    endTime: _vm.searchParams.endTime,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "startTime", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "startTime", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                scope.row.modifyFlag
                  ? _c("v-button", {
                      attrs: {
                        text: "编辑",
                        type: "text",
                        permission: "update",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.edit(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                scope.row.status === 1 && scope.row.modifyFlag
                  ? _c("v-button", {
                      attrs: {
                        text: "关闭",
                        type: "text",
                        permission: "delete",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.offHandle(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                _c("v-button", {
                  attrs: { text: "查看", type: "text", permission: "view" },
                  on: {
                    click: function ($event) {
                      return _vm.clickNotice(scope.row)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _vm.noticeDialog
        ? _c("notice-view", {
            attrs: { id: _vm.noticeId, detailVisible: _vm.noticeDialog },
            on: {
              "update:detailVisible": function ($event) {
                _vm.noticeDialog = $event
              },
              "update:detail-visible": function ($event) {
                _vm.noticeDialog = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }